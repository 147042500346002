<template>
  <div class="col-12 col-lg-6 c g">
    <br>
    <div class="card">
        <div class="card-body" v-html="ask.html"></div>
        <div class="card-footer" v-if="ask.allow_teacher_reply && !ask.teacher_reply">
            <p>تقديم الافادة</p>
            <div class="form-group">
              <textarea class="form-control" v-model="reply" rows="3" placeholder="اكتب الافادة/الرد هنا..."></textarea>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="send()">
                    ارسال <i class="fa fa-arrow-left"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 text-center text-muted">
        الخدمة مقدمة من <a href="https://tahdir.net">التحضير الذكي</a>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            ask: {},
            reply: ""
        }
    },
    created(){
        var g = this;
        $.post(api + '/general/teacher-ask', {
            id: window.location.href.split("ask/")[1]
        }).then(function(r){
            g.step = 1
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.ask = r.response;
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200);
        })
    },
    methods: {
        send(){
          var g = this;
            $.post(api + '/general/teacher-ask-send', {
                id: window.location.href.split("ask/")[1],
                reply: g.reply
            }).then(function(r){
                g.step = 1
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الارسال بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200);
            })  
        }
    }
}
</script>

<style>

</style>